import React, { useRef, useCallback, useState } from "react";
import './App.css';

import p5 from 'p5';

//import { useP5 } from '@gen/react-use-p5';
import {getRandomInt} from './helpers/bazUtils'

import { powerGame } from './sketches/powerGame';
import { likeTheWorld } from './sketches/likeTheWorld';
import { simpleClock } from './sketches/simpleClock';
import { topNews } from './sketches/topNews';
import { tecnocity } from './sketches/tecnocity';
import { circleart } from './sketches/circleart';
import {newsArt} from './sketches/newsArt'
import { rectart } from './sketches/rectart';
import { inwork } from './sketches/inwork';


const sketches={
  "powergame" :{
    "class" : powerGame,
    "title" : "Power Game",
    "note":"@bazzani - gen 2022"
  },
  "liketheworld" :{
    "class" : likeTheWorld,
    "title" : "Like The World",
    "note":"@bazzani - gen 2022"
  },
  "simpleclock" :{
    "class" : simpleClock,
    "title" : "Simple Clock",
    "note":"@bazzani - gen 2022"
  },
  "topnews" :{
    "class" : topNews,
    "title" : "Top News",
    "note":"@bazzani"
  },
  "tecnocity" :{
    "class" : tecnocity,
    "title" : "TecnoCity",
    "note":"@bazzani"
  },
  "circleart" :{
    "class" : circleart,
    "title" : "CircleArt",
    "note":"@bazzani"
  },
  "newsart" :{
    "class" : newsArt,
    "title" : "NewsArt",
    "note":"@bazzani"
  },
  "rectart" :{
    "class" : rectart,
    "title" : "RectangleArt",
    "note":"@bazzani"
  }
}

console.log("path " + window.location.pathname);
var insk=window.location.pathname.replace("/","");

var sketch=null;
if(insk=="inwork"){
  sketch={
    "class" : inwork,
    "title" : "on working",
    "note":"@bazzani"
  }
} else {
  sketch=sketches[insk.toLowerCase()];
}

if(!sketch){
  var keyssk=Object.keys(sketches);
  sketch=sketches[keyssk[getRandomInt(0,keyssk.length-1)]];

}

const getClosestSketch = (sketch, next) => {
  var keyssk=Object.keys(sketches);
  for(var i=0; i<keyssk.length; i++ ){
    if(sketches[keyssk[i]].class===sketch.class){
      var newIndex= (i==keyssk.length-1)?0:i+1;
      if(!next) {newIndex= (i==0)?keyssk.length-1:i-1};

      return sketches[keyssk[newIndex]];
    }
  }
}

var useP5 = function useP5(sketch) {
  var p5Ref = useRef(null);
  var canvasParentRef = useRef(null);
  var setCanvasParentRef = useCallback(function (node) {
    if (node) {
      //bl non trovo altra soluzione per non lasciare p5 pending
      if (p5Ref.current) p5Ref.current.remove();
      if (p5Ref.current) p5Ref.current.remove();
      if (p5Ref.current) p5Ref.current.remove();
      if (p5Ref.current) p5Ref.current.remove();
      if (p5Ref.current) p5Ref.current.remove();
      if (p5Ref.current) p5Ref.current.remove();
      p5Ref.current = new p5(sketch, node);
    } else {
      if (p5Ref.current) p5Ref.current.remove();
    }

    canvasParentRef.current = node;
  }, [sketch]);

  ////BL 21 feb -- aggiungo il remove per evitare problemi con il resize
  //if(p5Ref.current) {p5Ref.current.remove();}

  return [setCanvasParentRef, p5Ref.current];
};


const Component = ({ sketch }) => {
  const [setRef] = useP5(sketch);
  return <div ref={setRef}></div>
}

/*
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};
*/


function App() {
  const [currentSketch, setCurrentSketch] = useState(sketch);

  React.useEffect(() => {

    var url="https://xpapi.xpilon.com/api/bfLogin?insk="+insk+"/"+currentSketch.title;
    var request = new XMLHttpRequest();
    console.log(url);
    request.open('GET', url, false);
    request.send(null);
    

  });

  return (
    <div className="App">
      <div className="Main">
        <div className="MyHeader">
          <div className="JobDetail" onClick={e => setCurrentSketch(getClosestSketch(currentSketch,false))}>
            {'<< prev'}
          </div>
          < div className="JobDetail">
            {currentSketch.title} {currentSketch.note} 
          </div>
          <div className="JobDetail" onClick={e => setCurrentSketch(getClosestSketch(currentSketch,true))}>
            {'next >>'}
          </div>
        </div>

        <div className="Sketch">
          <Component sketch={currentSketch.class}/>
        </div>
      </div>

      <div className="MyTag">
        <div className="Hash">#GenerativeArt</div>
        <div className="Hash">#CreativeCoding</div>
        <div className="Hash">#MathAts</div>
        <div className="Hash">#P5JS</div>
      </div>
    </div>
  );
}




export default App;
