
import moment from 'moment';
import bfsetting from  '../bfsettings';

function getRandomInt(min, max) {
    if(!max) { //gestione parametro opzionale
      max=min;
      min=0;
    }
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

var cnv;

var cnvWidth;
var cnvHeight;
var scaleWidth = bfsetting.canvasScaleWidth;
var scaleHeight = bfsetting.canvasScaleHeight;



var frameRate=10;

var videoWriter;
var captured=false;

var flags=require("../data/flags.json");
var dataSource=require("../data/s_ra_stats_weeks.json");
var dataSet=dataSource.dataSet;
var unit=dataSource.unit;
var numSlot=dataSet.length;
var curretnSlot=0;
var timeSlot=40; //frame
var slotPrev=null;
var slotCurrent=null;
var slotStart=0; //nel caso in cui serve sltare i primi slot
var slotFrame=0;
var bars=[];
var dataMax=0;
var headerHeight;
var contentHeight;
var footerHeight;
var limitPerc=0;
var maxBars=10; //numero di barre totali

var flagImages=[];
var roundValues=true;


var imgs_url="https://news.bazzify.com/flags/";

var bg_img=null;

const init = (p5) => {
    if(bfsetting.enableVideoSaving){
        cnvHeight=800;
        cnvWidth=1200;
    }
    else{
        cnvHeight=p5.round(window.innerHeight*scaleHeight);
        cnvWidth=cnvHeight/2*3;//p5.round(window.innerWidth*scaleWidth);
    }


    p5.loadImage("https://news.bazzify.com/bg/bt_s_ra_stats_weeks.png", (img) => {
        bg_img=img
    }, (err) => {
        console.log(err)} 
    );

    

    curretnSlot= slotStart;

    for(var i=slotStart; i< numSlot; i++){
        var slot=dataSet[i];
        slot.data.sort(function(d1,d2) {
            return parseInt(d2.value)-parseInt(d1.value);
        });

        const slotMax = Math.max.apply(Math, slot.data.map(function(o) { return o.value; }))

        if(dataMax<slotMax) {dataMax=slotMax}

        for(var d=0;d<slot.data.length;d++) {
            //carico le bandiere
            var name=slot.data[d].country;
            if(!flagImages[name]){
                if(flags[name]){
                    flagImages[name]=p5.loadImage(imgs_url+flags[name].file);
                } else {
                    console.log("flag not found " + name);
                }
            }
        }
    }

    headerHeight=cnvHeight*0.2;
    footerHeight=cnvHeight*0.15;
    contentHeight=cnvHeight-headerHeight-footerHeight;

    cnv=(!cnv)?(p5.createCanvas(cnvWidth, cnvHeight)):(p5.resizeCanvas(cnvWidth, cnvHeight))
    p5.frameRate(frameRate);
}

export const inwork = (p5) => {    
    p5.setup = () => {

        init(p5);
        if(bfsetting.enableVideoSaving){
            videoWriter = new window.WebMWriter({
                quality: 0.95,    // WebM image quality from 0.0 (worst) to 0.99999 (best), 1.00 (VP8L lossless) is not supported
                fileWriter: null, // FileWriter in order to stream to a file instead of buffering to memory (optional)
                fd: null,         // Node.js file handle to write to instead of buffering to memory (optional)
            
                // You must supply one of:
                frameDuration: 1000/frameRate, // Duration of frames in milliseconds
                frameRate: null,     // Number of frames per second
            
                transparent: false,      // True if an alpha channel should be included in the video
                alphaQuality: undefined, // Allows you to set the quality level of the alpha channel separately.
                                         // If not specified this defaults to the same value as `quality`.
            });
        }
    };
      
    
    p5.draw = () => {


        if((slotFrame==0) || slotFrame ==timeSlot){ //inizializzo le barre
            slotFrame=0;

            slotPrev=(curretnSlot>1)?dataSet[curretnSlot-1]:[];
            slotCurrent=dataSet[curretnSlot];

            if(!slotCurrent){ //end
                if(bfsetting.enableVideoSaving) {
                    /** allungo con l'utimo frame */
                    for(var i=0;i<120;i++){
                        videoWriter.addFrame(document.querySelector('#defaultCanvas0.p5Canvas')); 
                    }
                    videoWriter.complete().then(function(webMBlob) {
                        var objUrl=URL.createObjectURL(webMBlob);
                        saveBlob(objUrl,"video.webm");
                    });
                }
                p5.noLoop();
                return;
            }

            //aggiorno i dati
            var lastNew=0;
            for(var i=0; i< slotCurrent.data.length; i++){
                var d=slotCurrent.data[i];
                if(d.value> dataMax*limitPerc/100 )
                {
                    var b=bars.find(e => e.key === (d.country + d.name) );
                    if(b){ //esiste già la barra
                        b.startPosition=b.endPosition;
                        b.startValue=b.endValue;
                        b.endPosition=i+1;
                        b.endValue=(roundValues)?Math.round(d.value):d.value;
                        b.open=d.open;
                        b.close=d.close;
                    } else { //non esiste la barra
                        b=new Bar();
                        b.name=d.name;
                        b.country=d.country;
                        b.key=d.country + d.name;
                        b.endPosition=i+1;
                        b.endValue=(roundValues)?Math.round(d.value):d.value; 
                        b.startPosition=maxBars+1;
                        b.open=d.open;
                        b.close=d.close;
                        bars.push(b);
                    }
                    b.lastSlotUpdate=curretnSlot;
                    lastNew=i+1;
                }

            }
            //sistemo i dati che non ci sono più
            for(var i=0; i< bars.length; i++){
                var b=bars[i];
                if(b.lastSlotUpdate!=curretnSlot){
                    b.endPosition=lastNew++;
                    b.endValue=0;
                }
            }
            
            //ordino le barre per valore
            bars.sort(function(d1,d2) {
                return d1.endPosition-d2.endPosition;
            });
            
            curretnSlot++;

        }

        slotFrame++;

        p5.background(210,255);


        (bg_img) && p5.image(bg_img,0,0, cnvWidth,cnvHeight);

        //titolo
        p5.fill(10,220);
        var title=dataSource.title
        var titlerSize=cnvWidth/title.length*1.5;
        p5.textSize(titlerSize);
        //p5.text(title, cnvWidth/2-p5.textWidth(title)/2, titlerSize);
        

        p5.fill(180,255);
        //p5.rect(0,headerHeight-yearSize,cnvWidth,yearSize);

        //disegno le barre
        for(var i=0; (i< bars.length); i++){
            var b=bars[i];
            b.drow(p5);
        }

        //line
        //p5.stroke(100,100);
        //p5.line(0,cnvHeight-footerHeight,cnvWidth,cnvHeight-footerHeight);

        //footer
        p5.fill(50,255);
        p5.noStroke();
        var noteSize=12;
        p5.textSize(noteSize);
        p5.text(dataSource.subtitle, 10, cnvHeight-noteSize*3);
        //p5.text("Source: " + dataSource.source, 10, cnvHeight-noteSize);
        //p5.text("Designed By - Lorenzo Bazzani", 10, cnvHeight-noteSize);
        

        //progressione in sovraimpressione
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const startDate=new Date(dataSet[curretnSlot-1].startDay);
        const endDate=new Date(dataSet[curretnSlot-1].startDay);
        endDate.setDate(startDate.getDate() + 6);
        const start=""+startDate.getDate()+"-"+month[startDate.getMonth()]+"\n"+endDate.getDate()+"-"+month[endDate.getMonth()];

        const timeLabel= `${start}`;
        p5.fill(50,150);
        p5.noStroke();
        p5.textSize(cnvWidth/15);
        p5.text(timeLabel, cnvWidth-300, cnvHeight*0.7);


        if(bfsetting.enableVideoSaving) {
            (bg_img) && videoWriter.addFrame(document.querySelector('#defaultCanvas0.p5Canvas')); 
        }
    };

    p5.touchStarted = () => {
        if(p5.mouseY>0 && p5.mouseY<cnvHeight){
            p5.isLooping()?p5.noLoop():p5.loop()
            return false;
        }
      }

    p5.mousePressed = () => {
        console.log("mouse on: " + p5.mouseX + "," + p5.mouseY);
        if(p5.mouseY>0 && p5.mouseY<cnvHeight){
            p5.isLooping()?p5.noLoop():p5.loop()
            return false;
        }
    }

    p5.windowResized = () => {
        cnvHeight=p5.windowHeight*scaleHeight;
        cnvWidth=p5.min(p5.windowWidth*scaleWidth, cnvHeight) ;
        init(p5);  
    }
  
};

class Bar {
    constructor() {
        this.startValue=0;
        this.endValue=0;
        this.startPosition=1;
        this.endPosition=1;
        this.lastSlotUpdate=-1;
        this.r=getRandomInt(100,255);
        this.g=getRandomInt(100,255);
        this.b=getRandomInt(100,255);
        this.name="";
        this.key="";
        this.img=null;

    }  
    drow(p5){
        var barHeight=contentHeight/maxBars;
        var linearProgression=1/timeSlot*(slotFrame);
        
        var startY=headerHeight+(this.startPosition-1)*barHeight;
        var endY=headerHeight+(this.endPosition-1)*barHeight;

        var y=startY+ (endY-startY)*linearProgression;


        var currentValue=parseInt(this.startValue)+parseInt(((this.endValue-this.startValue))*linearProgression);
        if(slotFrame==timeSlot){
            currentValue=this.endValue;
        }

        var xWidth=currentValue/dataMax*cnvWidth;

        if(y<(headerHeight+barHeight*maxBars)){
            p5.fill(220,180,180,140);
            var barMargin=barHeight*0.05;
            p5.rect(0,y+barMargin,xWidth,barHeight-(barMargin*2),0,10,10,0);
            var imgWidth=0;

            if(flagImages[this.country]){
                var imgHeight=Math.min(80,barHeight-(barMargin*2));
                var imgWidth=imgHeight/2*3;
                p5.image(flagImages[this.country],5,y+(barHeight/2)-imgHeight/2,imgWidth,imgHeight);
            }

            p5.noStroke();
            p5.fill(50,220);
            var label= this.country + " " + this.name + ": " + currentValue + " backlog - open: " + this.open + " closed: " + this.close;
            var labelSize=cnvWidth/maxBars/4;
            p5.textSize(labelSize);
            p5.text(label , 10+imgWidth, y+barHeight/2+labelSize/2 );
        }


    
    }
}



var saveBlob = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (objUrl, fileName) {
        var url = objUrl;
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());
