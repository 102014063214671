
import moment from 'moment';
import bfsetting from  '../bfsettings';

function getRandomInt(min, max) {
    if(!max) { //gestione parametro opzionale
      max=min;
      min=0;
    }
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

var cnv;

var cnvWidth;
var cnvHeight;
var scaleWidth = bfsetting.canvasScaleWidth;
var scaleHeight = bfsetting.canvasScaleHeight;
var sceneFrame=-1;
var sceneDuration=0.5; //sec
var frameRate=20;

var videoWriter;
var captured=false;

const hMargin=1;
var current_news;
var current_image;
var iW;
var iH;


var s=null;

var news=null;

var server_url="https://news.bazzify.com";

const init = (p5) => {
    var url=server_url + "/top1000news.json";
    var request = new XMLHttpRequest();
    console.log(url);
    request.open('GET', url, false);
    request.send(null);
    var data=request.response;
    news=JSON.parse(data);

    if(bfsetting.enableVideoSaving){
        cnvHeight=500;
        cnvWidth=500;
    }
    else{
        cnvHeight=p5.round(window.innerHeight*scaleHeight);
        cnvWidth=p5.round(window.innerWidth*scaleWidth);
    }

    cnv=(!cnv)?(p5.createCanvas(cnvWidth, cnvHeight)):(p5.resizeCanvas(cnvWidth, cnvHeight))
    p5.frameRate(frameRate);
    sceneFrame=-1;
}

export const newsArt = (p5) => {    
    p5.setup = () => {


        init(p5);
        if(bfsetting.enableVideoSaving){
            videoWriter = new window.WebMWriter({
                quality: 0.95,    // WebM image quality from 0.0 (worst) to 0.99999 (best), 1.00 (VP8L lossless) is not supported
                fileWriter: null, // FileWriter in order to stream to a file instead of buffering to memory (optional)
                fd: null,         // Node.js file handle to write to instead of buffering to memory (optional)
            
                // You must supply one of:
                frameDuration: 1000/frameRate*2, // Duration of frames in milliseconds
                frameRate: null,     // Number of frames per second
            
                transparent: false,      // True if an alpha channel should be included in the video
                alphaQuality: undefined, // Allows you to set the quality level of the alpha channel separately.
                                        // If not specified this defaults to the same value as `quality`.
            });
        }
    };
      
    
    p5.draw = () => {
        if(sceneFrame==-1 || sceneFrame==sceneDuration*frameRate) {
            p5.noLoop();
            if(!news || news.length==0) {init();}
            var index=getRandomInt(0,news.length-1);
            current_news=news[index];
            news.splice(index, 1); //rimuovo la news visualizzata
            p5.loadImage(server_url+current_news.local_image,(imageLoaded) => {
                current_image=imageLoaded;
                s = new Scene();
                sceneFrame=0;
                console.log("new scene at " + p5.minute() + ":" +p5.second());
                p5.loop();
            } );
            return;
        }
        s && s.drow(p5);
    };

    p5.touchStarted = () => {
        if(p5.mouseY>0 && p5.mouseY<cnvHeight){
            p5.isLooping()?p5.noLoop():p5.loop()
            return false;
        }
      }

    p5.mousePressed = () => {
        console.log("mouse on: " + p5.mouseX + "," + p5.mouseY);
        if(p5.mouseY>0 && p5.mouseY<cnvHeight){
            p5.isLooping()?p5.noLoop():p5.loop()
            return false;
        }
    }

    p5.windowResized = () => {
        cnvHeight=p5.windowHeight*scaleHeight;
        cnvWidth=p5.min(p5.windowWidth*scaleWidth, cnvHeight) ;
        init(p5);  
    }
  
};

class Scene {
    constructor() {
        this.scale=getRandomInt(20,30)/100;

        this.x=getRandomInt(cnvWidth*0.1,cnvWidth*0.9);
        this.y=getRandomInt(cnvHeight*0.1,cnvHeight*0.9);
        this.rotate=Math.PI/getRandomInt(8,100)*(getRandomInt(0,1)?-1:1);
        this.rnd5=getRandomInt(10,80)/100;

        
    } 

    preload(p5){
        console.log("preload");
    }
    
    drow(p5) { 

        var scale=1/(frameRate*(sceneDuration)) * (sceneFrame+1);
        var scaleSin=p5.sin(scale*p5.PI);

        iW=current_image.width;
        iH=current_image.height;
        
        var imgScale=this.scale*scale;

        const iScale=iW/iH;
        

        if(iW>cnvWidth){
            iW=cnvWidth*imgScale;
            iH=iW/iScale;
        }

        iH=cnvHeight*imgScale;
        iW=iH*iScale;

        p5.push();


        var iX=this.x-iW/2;
        var iY=this.y-iH/2;
        p5.rotate(this.rotate);
        p5.image(current_image, iX, iY, iW,iH);

        p5.noFill();
        p5.stroke(255);
        p5.strokeWeight(14*scale);
        p5.rect(iX, iY, iW,iH);

        sceneFrame++;

        var postdate =moment(current_news.postdate).fromNow();
        var domain=current_news.domain + " - " + postdate
        var title=current_news.title;

        p5.noStroke();
        
        p5.textFont('Georgia');
        p5.fill(0);
        p5.textSize(10*scale);
        p5.text(domain,iX+2, iY+1 );

        p5.fill(0);
        p5.textSize(p5.min(10*scale,iW/title.length*2*scale));
        p5.text(title,iX+2, iY+iH+2);



        p5.pop();

        if(bfsetting.enableVideoSaving) {
            if(p5.frameCount<=60*frameRate){
                //var dataurl=document.querySelector('#defaultCanvas0.p5Canvas').toDataURL();
                videoWriter.addFrame(document.querySelector('#defaultCanvas0.p5Canvas'));
            }
            else{
                if(!captured){
                    captured=true;
                    videoWriter.complete().then(function(webMBlob) {
                        var objUrl=URL.createObjectURL(webMBlob);
                        saveBlob(objUrl,"video.webm");
                    });
                }
            }
        }


        

    }

}

var saveBlob = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (objUrl, fileName) {
        var url = objUrl;
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());
