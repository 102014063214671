import bfsetting from  '../bfsettings';


var saveBlob = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (objUrl, fileName) {
        var url = objUrl;
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

function mapRange(n, start1, stop1, start2, stop2, withinBounds) {
    const newval = (n - start1) / (stop1 - start1) * (stop2 - start2) + start2;
    if (!withinBounds) {
      return newval;
    }
    if (start2 < stop2) {
      return constrain(newval, start2, stop2);
    } else {
      return constrain(newval, stop2, start2);
    }
};
function constrain(n, low, high) {
    return Math.max(Math.min(n, high), low);
};


function riposizionaSuSfera(x,y, raggio){
    var longitude=mapRange(x+raggio,0, raggio*2,0 , Math.PI) -Math.PI/2;
    var latitude=mapRange(y+raggio,0, raggio*2, 0, Math.PI) -Math.PI/2;
    x=raggio*Math.cos(latitude)*Math.sin(longitude);
    y=raggio*(Math.sin(latitude));
    return {x:x,y:y};
}

function heart(p5, x, y, size) {
    y=x-size/3;
    p5.beginShape();
    p5.vertex(x, y);
    p5.bezierVertex(x - size / 2, y - size / 2, x - size, y + size / 3, x, y + size);
    p5.bezierVertex(x + size, y + size / 3, x + size / 2, y - size / 2, x, y);
    p5.endShape(p5.CLOSE);
  }

var cnvWidth;
var cnvHeight;
var scaleWidth = bfsetting.canvasScaleWidth;
var scaleHeight = bfsetting.canvasScaleHeight;
var frameRate=10;
var speed=1;
var raggio;
var centroX;
var centroY;
var mapWidth;
var mapHeight;
var cnv;
var videoWriter;
var captured=false;

var r;

var shapes=null;

export const likeTheWorld = (p5) => {    
    const init = () => {
        cnvHeight=p5.round(window.innerHeight*scaleHeight);
        cnvWidth=p5.round(window.innerWidth*scaleWidth);
        raggio=Math.min(cnvWidth,cnvHeight)*0.9/2;
        mapWidth=raggio*4;
        mapHeight=mapWidth/2;
        centroX=cnvWidth/2;
        centroY=cnvHeight/2;
        r=0;
        var url="https://xpapi.xpilon.com/api/bfCoastline?cnvWidth="+mapWidth+"&cnvHeight="+mapHeight;
        var request = new XMLHttpRequest();
        console.log(url);
        request.open('GET', url, false);
        request.send(null);
        var data=request.response;
        shapes=JSON.parse(data);
    }

    p5.setup = () => {
        init();
        cnv=(!cnv)?(p5.createCanvas(cnvWidth, cnvHeight)):(p5.resizeCanvas(cnvWidth, cnvHeight)) 

        if(bfsetting.enableVideoSaving){
            videoWriter = new window.WebMWriter({
                quality: 0.95,    // WebM image quality from 0.0 (worst) to 0.99999 (best), 1.00 (VP8L lossless) is not supported
                fileWriter: null, // FileWriter in order to stream to a file instead of buffering to memory (optional)
                fd: null,         // Node.js file handle to write to instead of buffering to memory (optional)
            
                // You must supply one of:
                frameDuration: 1000/frameRate*2, // Duration of frames in milliseconds
                frameRate: null,     // Number of frames per second
            
                transparent: false,      // True if an alpha channel should be included in the video
                alphaQuality: undefined, // Allows you to set the quality level of the alpha channel separately.
                                        // If not specified this defaults to the same value as `quality`.
            });
        }
        p5.frameRate(frameRate);

    };
      
    
    p5.draw = () => {
        if(!shapes){
            return;
        }

        let start = p5.millis();
        
        r=r+speed; //velocità rotazione
        (r>raggio*4) && (r=0);

        p5.background(0);
        p5.noStroke();
        p5.push();
        p5.translate(centroX,centroY);
        p5.fill(100,70);
        p5.ellipse(0, 0, raggio*2, raggio*2);
        //hart
        var hartLoop=Math.round(r%(raggio))-raggio/2;
        p5.fill(255,0,0,50);
        var hDim=Math.abs(hartLoop*0.8)+raggio;
        heart(p5,0,0, hDim);

        
        for(var s=0; s<shapes.length; s++){
            //p5.beginShape();

            var coordinates=shapes[s];
            for(var c=0; c<coordinates.length; c++){
                var coordinate=coordinates[c];
                var x1=coordinate[0]+r;
                var x2=coordinate[2]+r;          
                if(x1>mapWidth || x2>mapWidth) 
                {
                    x1=x1-mapWidth;
                    x2=x2-mapWidth;
                }
                x1=x1-raggio;
                x2=x2-raggio;
                var y1=(coordinate[1]-raggio);
                var y2=coordinate[3]-raggio;
                
                //sposto dietro i punti fuori
                var isBack=false;
                
                if(x1>=raggio || x2>=raggio){
                    x1=raggio - (x1-raggio);
                    x2=raggio - (x2-raggio);
                    isBack=true;
                    
                }

                var p1=riposizionaSuSfera(x1,y1,raggio);
                var p2=riposizionaSuSfera(x2,y2,raggio);

                var uDist=p5.dist(0,0,x1,y1)/raggio;
                
                (!isBack)?p5.stroke(255,255-20*uDist):p5.stroke(255,10+20*uDist)
                

                p5.line(p1.x,p1.y,p2.x,p2.y);
                //p5.vertex(p2.x,p2.y);
            }
            //p5.endShape();
        }
        
        p5.pop();

        p5.strokeWeight(1);
        p5.noStroke();
        p5.fill(255,180);
        p5.textSize(12);
        p5.text("@bazzani - gen 22", cnvWidth-108, cnvHeight-10 );
        
        if(bfsetting.enableVideoSaving) {
            if(p5.frameCount*speed<raggio*4){
                //var dataurl=document.querySelector('#defaultCanvas0.p5Canvas').toDataURL();
                videoWriter.addFrame(document.querySelector('#defaultCanvas0.p5Canvas'));
            }
            else{
                if(!captured){
                    captured=true;
                    videoWriter.complete().then(function(webMBlob) {
                        var objUrl=URL.createObjectURL(webMBlob);
                        saveBlob(objUrl,"video.webm");
                    });
                }
            }
        }
        

        let end = p5.millis();
        let elapsed = Math.round(end - start);

        if(r==10){
            //p5.save("world");
        }


        p5.text("Drow in: " + elapsed + "ms.", 2, cnvHeight -10 );

    };

    p5.mousePressed = () => {
        
    }

    
    p5.windowResized = () => {
        init();
        cnvWidth=p5.windowWidth;
        cnvHeight=p5.windowHeight*scaleHeight;
        cnv=(!cnv)?(p5.createCanvas(cnvWidth, cnvHeight)):(p5.resizeCanvas(cnvWidth, cnvHeight))    
    }
  
    
};

 
